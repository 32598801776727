import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 500px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  div {
    width: calc(50% - 16px);
    background: #f8f5f5 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 5px #00000029;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;

    &:last-child,
    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }

  h2 {
    color: #707070;
    text-align: center;
    font-size: 14px;
    margin: 0;

    span {
      font-weight: 300;
      font-size: 16px;
    }
  }

  img {
    min-width: 24px;
    margin-bottom: 4px;
  }
`
