import * as React from "react"

import Layout from "../components/Layout/"
import Seo from "../components/seo"
import Hero from "../components/Hero"
import Header from "../components/Header"
import Description from "../components/Description"
import Benefits from "../components/Benefits"
import About from "../components/About"
import Divider from "../components/Divider"
import Contact from "../components/Contact"
import FooterImage from "../components/FooterImage"

const IndexPage = () => (
  <Layout>
    <Seo />
    <Header />
    <Hero />
    <Description />
    <Benefits />
    <Divider />
    <About />
    <Divider />
    <Contact />
    <FooterImage />
  </Layout>
)

export default IndexPage
