import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import * as S from "./styles"

const Contact = () => (
  <S.Wrapper>
    <p>Quer conhecer mais sobre a Vert Green® Jardins Verticais?</p>
    <S.Form
      action="https://formsubmit.co/nathaliaosena@gmail.com"
      method="POST"
    >
      <input type="text" name="_honey" style={{ display: "none" }} />
      <input type="hidden" name="_captcha" value="false" />
      <input
        type="hidden"
        name="_next"
        value="https://revendavertgreen.com.br/sucesso"
      />
      <input type="text" name="nome" required placeholder="Nome" />
      <input type="text" name="cidade" required placeholder="Cidade" />
      <input type="email" name="email" required placeholder="Email" />
      <input type="tel" name="telefone" required placeholder="Telefone" />
      <S.RadioWrapper>
        <label for="arquiteto">
          <input
            type="radio"
            id="arquiteto"
            name="tipo_contato"
            value="arquiteto"
          />
          <span>Sou arquiteto(a)</span>
        </label>
        <label for="consumidor">
          <input
            type="radio"
            id="consumidor"
            name="tipo_contato"
            value="consumidor"
          />
          <span>Sou consumidor final</span>
        </label>
        <label for="revenda">
          <input
            type="radio"
            id="revenda"
            name="tipo_contato"
            value="revenda"
          />
          <span>Quero ser um revendedor</span>
        </label>
      </S.RadioWrapper>
      <S.Button type="submit">Enviar</S.Button>
    </S.Form>
    <S.PhraseWrapper>
      <S.H4>São verdadeiras obras de arte,</S.H4>
      <StaticImage
        src="../../images/phrase-image.png"
        width={600}
        quality={100}
        alt="Vert Green - Jardins Verticais"
        style={{ marginBottom: `1.45rem` }}
      />
    </S.PhraseWrapper>
  </S.Wrapper>
)

export default Contact
