import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 500px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div:first-child {
    width: 50%;
  }

  & > div:last-child {
    width: 45%;
  }

  p {
    line-height: 1.5;
    color: #707070;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    max-width: 460px;
    margin: 0 auto;
  }
`
