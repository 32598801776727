import * as React from "react"

import * as S from "./styles"

import exclusivo from "../../images/sistema-exclusivo.svg"
import impermeavel from "../../images/produto-100-impermeavel.svg"
import acabamento from "../../images/melhor-acabamento.svg"
import durabilidade from "../../images/maior-durabilidade.svg"
import reservatorio from "../../images/reservatorio.svg"
import bolsas from "../../images/bolsas.svg"

const Benefits = () => (
  <S.Wrapper>
    <div>
      <img src={exclusivo} alt="Sistema Exclusivo" />
      <h2>
        Sistema
        <br />
        <span>exclusivo</span>
      </h2>
    </div>
    <div>
      <img src={impermeavel} alt="Produto 100% impermeável" />
      <h2>
        Produto
        <br />
        <span>100% impermeável</span>
      </h2>
    </div>
    <div>
      <img src={acabamento} alt="Produto 100% impermeável" />
      <h2>
        Melhor
        <br />
        <span>acabamento</span>
      </h2>
    </div>
    <div>
      <img src={durabilidade} alt="Maior durabilidade" />
      <h2>
        Maior
        <br />
        <span>durabilidade</span>
      </h2>
    </div>
    <div>
      <img
        src={reservatorio}
        alt="Sistema com reservatório na própria estrutura"
      />
      <h2>
        Sistema com
        <br />
        <span>reservatório na própria estrutura</span>
      </h2>
    </div>
    <div>
      <img
        src={bolsas}
        alt="Bolsas em feltro 6mm (dupla camada)"
        style={{ width: "36px", marginBottom: "10px" }}
      />
      <h2>
        Bolsas
        <br />
        <span>em feltro 6mm (dupla camada)</span>
      </h2>
    </div>
  </S.Wrapper>
)

export default Benefits
