import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 560px;
  padding: 16px;

  p {
    line-height: 1.5;
    color: #707070;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    max-width: 460px;
    margin: 0 auto 20px;
  }
`

export const Form = styled.form`
  width: 100%;

  input:not([type="radio"]) {
    width: 100%;
    background: #efeded;
    border: none;
    margin: 6px 0;
    padding: 8px 16px;
    border-radius: 16px;
  }
`

export const RadioWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 6px 0;

  label {
    display: flex;
    align-items: flex-start;
    max-width: 100px;
  }

  span {
    font-size: 9px;
    font-weight: bold;
    color: #707070;
    text-transform: uppercase;
    margin-left: 4px;
  }
`

export const Button = styled.button`
  display: block;
  margin: 32px auto 16px;
  background: #427c48;
  color: #fff;
  padding: 8px 24px;
  border: none;
  border-radius: 20px;
`

export const PhraseWrapper = styled.div`
  max-width: 360px;
  margin: 0 auto;
`

export const H4 = styled.h4`
  color: #707070;
  font-weight: lighter;
  text-align: center;
  font-size: 16px;
  margin: 40px 0 -16px;
`
