import * as React from "react"

import * as S from "./styles"

const Divider = () => (
  <S.Wrapper>
    <hr />
  </S.Wrapper>
)

export default Divider
