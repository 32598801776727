import * as React from "react"

import * as S from "./styles"

import logo from "../../images/vert-green-logo-horizontal.svg"

const Header = () => (
  <S.Header>
    <img src={logo} alt="Vertgreen" />
    <p>Um produto único e com sistema exclusivo</p>
  </S.Header>
)

export default Header
