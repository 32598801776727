import * as React from "react"

import * as S from "./styles"

const Description = () => (
  <S.Wrapper>
    <p>
      A Vert Green® permite implantar em qualquer superfície vertical um
      ecossistema natural, totalmente independente, com o melhor acabamento,
      maior durabilidade, criando paredes verdes com as mais variadas formas,
      texturas, tamanhos e 100% impermeável.
    </p>
  </S.Wrapper>
)

export default Description
