import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import * as S from "./styles"

const About = () => (
  <S.Wrapper>
    <div>
      <StaticImage
        src="../../images/vertgreen-jardim-pergolado.png"
        width={600}
        quality={100}
        alt="Vert Green - Jardins Verticais"
        style={{ marginBottom: `1.45rem` }}
      />
    </div>
    <div>
      <p>
        Estamos no mercado desde 2010, atuamos em 12 estados do país e no DF
        criando paredes verdes com as mais variadas formas, texturas, tamanhos.
      </p>
    </div>
  </S.Wrapper>
)

export default About
