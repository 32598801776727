import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 8px auto;
  max-width: 600px;
  padding: 8px;

  hr {
    border: solid 1px #a8a8a8;
  }
`
