import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const FooterImage = () => (
  <StaticImage
    src="../../images/vertgreen-jardim-moldura-parede.png"
    width={600}
    quality={100}
    alt="Vert Green - Jardins Verticais"
    style={{ marginBottom: `-10px` }}
  />
)

export default FooterImage
