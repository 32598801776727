import styled from "styled-components"

export const Header = styled.header`
  margin: 0 auto;
  max-width: 600px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 120px;
  }

  p {
    color: #707070;
    font-size: 14px;
    margin-left: 30px;
  }
`
