import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding: 16px;

  p {
    line-height: 1.5;
    color: #707070;
    text-align: center;
    font-weight: 300;
    max-width: 460px;
    margin: 0 auto;
  }
`
